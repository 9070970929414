<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="py-4">
          Cookies Policy
        </h1>
        <p>
          TyresNParts ist zum Schutz ihrer personenbezogenen Daten und zu klaren und transparenten Angaben hinsichtlich der Art und der Verwendung der von uns erfassten Informationen verpflichtet. Wenn Sie die Website oder die Apps von TyresNParts nutzen, können mittels Cookies Informationen erfasst werden, die Sie von den anderen Nutzern unserer Website oder unserer Apps unterscheiden.
          In dieser Cookie-Richtlinie sind die Zwecke, für die wir und unsere Partner Cookies und ähnliche Technologien nutzen, sowie die Art beschrieben, wie Sie Ihre Präferenzen in Bezug auf Cookies verwalten können.
        </p>

        <h2 class="py-4">
          1. Wer sind wir und wie kann Kontakt mit uns aufgenommen werden
        </h2>
        <p>
          Unser vollständiger Firmennamen lautet TyresNParts GmbH („TyresNParts ”), und im Falle Ihrer direkten Nutzung unserer Website oder unsere Services agieren wir als Datenverantwortlicher.<br>
          Wenn Sie Kontakt mit uns aufnehmen wollen, stehen Ihnen mehrere Optionen zur Verfügung:<br>
        </p>
        <ul>
          <li>Sie können eine E-Mail senden an privacy@tyresnparts.com</li>
          <li>Sie können einen Brief schreiben, adressiert an TyresNParts GmbH,  The Squaire 17 – Am Flughafen, 60549 Frankfurt am Main</li>
          <li>Sie können ebenfalls mithilfe unseres Kontaktformulars auf unserer Website Kontakt mit uns aufnehmen</li>
          <li>Sie können ebenfalls telefonisch mit uns Kontakt aufnehmen +49(0) 69 509544580</li>
        </ul>
        <h2 class="py-4">
          2. Was ist ein Cookie?
        </h2>
        <p>
          Ein Cookie ist eine Textdatei oder ein Software-Element, die bzw. das Informationen über die Navigation Ihres Terminals im Internet aufzeichnet. Es wird vom Ihrem Browser gesteuert und enthält manchmal eine einmalige Zahl oder eine Zufallszahl.<br>
          Wenn TyresNParts „Cookies“ erwähnt, können dies Cookies oder sonstige ähnliche Technologien sein, die auf dem Zugang zu dem Gerät (Computer, Tablet, Smartphone) basieren, das von Ihnen bei Ihrem Besuch unserer Websites genutzt wird. Dritte Parteien, beispielsweise unsere Partner oder Dienstleister, können in Verbindung mit unseren Services Cookies und ähnliche Technologien nutzen.<br>
          Ein Cookie gestattet TyresNParts oder seinen Partnern, als Ersteller von Cookies, im Zeitraum seiner Gültigkeit die Erkennung Ihres Gerätes, wenn dies auf digitalen Inhalt zugreift, der Cookies vom selben Ersteller enthält.<br>
        </p>

        <h2 class="py-4">
          3. Sonstige Richtlinien, die Sie lesen sollten
        </h2>
        <p>
          Einige der Informationen aus Cookies lassen weder Ihre direkte noch indirekte Identifizierung zu noch stellen sie personenbezogene Daten dar. Andere Informationen lassen gegebenenfalls Ihre Identifizierung zu und unterliegen als solche der Datenschutzverordnung. Wenn Sie weitere Informationen hinsichtlich der Art, in welcher wir Ihre personenbezogenen Daten nutzen, speichern und schützen, und hinsichtlich Ihrer Rechte wünschen, empfehlen wir, dass Sie unsere Datenschutzrichtlinie und die Datenschutzrichtlinien unserer Partner lesen, die über die Cookies Management-Lösung verfügbar sind.
        </p>
        <h2 class="py-4">
          4. Weshalb verwendet TyresNParts Cookies?
        </h2>
        <p>
          TyresNParts sucht stets nach neuen Wegen, um seine Internet-Website(s) zu verbessern und Ihnen, dem Internet-Nutzer, bessere Services und Erfahrungen zu bieten. Folglich nutzen TyresNParts und seine Partner unterschiedliche Arten von Cookies, wie nachstehend beschrieben. Im Falle einiger Cookie-Kategorien (Werbe-Cookies) bedarf es aufgrund geltender Regelung vor der Installation des Cookies in Ihr Gerät oder vor dem Lesen des Cookies Ihrer vorherigen Zustimmung.<br>
          Sie haben in jedem Fall die Kontrolle über diese Cookies. Folglich haben Sie die Möglichkeit, Cookies abzulehnen, einzurichten oder zu vernichten.<br>
        </p>
        <h2 class="py-4">
          5. Wie informiert TyresNParts Sie über die von TyresNParts genutzten Cookies?
        </h2>
        <p>
          Wenn Sie erstmals die Website oder App von TyresNParts nutzen, werden Sie von der Cookies Management-Lösung auf diese Seite geleitet, die alle erforderlichen Informationen über die verwendeten Cookies enthält. Diese Cookie-Richtlinie ist auf dem „Cookies“-Tab auf der Fußzeile unserer Website verfügbar.<br>
        </p><h2 class="py-4">
          6. Wie können diese Cookies abgelehnt und/oder deinstalliert werden?
        </h2>
        <p>
          a. Browserkontrollen<br>
          Die meisten Browser gestatten Ihnen die Kontrolle von Cookies über die Cookie-Einstellungen, die angepasst werden können für die Erteilung Ihrer Zustimmung zur Verwendung von Cookies. Darüber hinaus werden Sie von den meisten Browsern zur Prüfung und Löschung von Cookies befähigt. Lesen Sie bitte die von Ihrem Browser-Hersteller gelieferte Dokumentation, um mehr über Browserkontrollen zu erfahren.
          Um mehr über die Konfiguration von Cookies zu erfahren, können Sie die folgende Website besuchen: https://www.youronlinechoices.com/<br>
          b. Verwalten Sie Ihre Präferenzen über eine Cookies Management-Lösung<br>
          TyresNParts bietet eine Cookie Präferenz-Lösung für die Verwaltung der Zustimmung der Nutzer zu unseren Websites oder Apps im Hinblick auf Cookies. Diese Zustimmung ermöglicht die Erfassung, Speicherung und Übertragung der Inhalte der Nutzer an TyresNParts und die betroffenen Partner.<br>
          Dieses Dashboard ermöglicht Ihnen die Angabe Ihrer Wahl während Ihres ersten Besuchs unserer Website. Sie können diese akzeptieren, konfigurieren, ablehnen oder auf unsere Cookie-Richtlinie zugreifen, indem Sie die in der Cookies Management-Lösung verfügbaren Tasten anklicken.<br>
          Sie können Ihre Präferenz entweder global oder nach Zwecken oder selbst nach Partner-(Lieferanten-) Kategorien angeben. Darüber hinaus haben Sie Zugang zu den Datenschutzrichtlinien aller Partner.<br>
          Es steht Ihnen frei, Cookies zu akzeptieren oder abzulehnen, beachten Sie jedoch, dass nach einer Ablehnung das Browsen auf unserer Website weniger benutzerfreundlich und der relevante Inhalt geringer sein könnten.<br>
          Die erteilte Zustimmung gilt für einen Zeitraum, der die Ablaufverzögerung des Cookies im Hinblick auf seinen Zweck nicht übersteigt, und nicht länger ist als im Sinne der Rechtsvorschriften erforderlich. Werden Cookies von Ihnen abgelehnt, speichert TyresNParts Ihre Wahl und zeigt Ihnen die Cookies Management-Lösung für einen definierten Zeitraum nicht mehr an. Wenn diese Sperrfrist abgelaufen ist oder wenn Sie das Cookie löschen, das die Speicherung Ihrer Wahl betrifft, wird Ihnen die Cookies Management-Lösung erneut vorgeschlagen.<br>
          Sie können ungeachtet Ihrer ursprünglichen Wahl von Zeit zu Zeit Ihre Zustimmung zurückziehen oder Ihre Einstellungen mittels der Cookies Management-Lösung anpassen.<br>
        </p>
        <h2 class="py-4">
          7. Welche Cookie-Typen werden von TyresNParts verwendet?
        </h2>
        <p>
          Ein Cookie kann auf unterschiedliche Weise klassifiziert werden.
          Entsprechend seiner Lebensdauer ist eine Cookie entweder ein:
        </p>
        <ul>
          <li>Session-Cookie, das gelöscht wird, wenn der Nutzer den Browser schließt.</li>
          <li>Persistent Cookie, das während eines vordefinierten Zeitraums auf dem Computer/Gerät des Benutzers verbleibt, entsprechend der zugewiesenen Zeitspanne und den Parametern Ihres Internet-Browsers. In diesem Fall kann die Lebensdauer eines Cookies 12 Monate nach dem Setzen des Cookies nicht überdauern. Angesichts der Domain, der sie angehören, sind sie entweder:</li>
          <li>Erstanbieter-Cookies, die vom Web-Server der besuchten Seite gesetzt werden und dieselbe Domain haben, in diesem Fall TyresNParts oder seine Prozessoren.</li>
          <li>Drittanbieter-Cookies, die von einer anderen Domain in die Domain der besuchten Seite gespeichert werden. Sie werden von einer dritten Partei, von TyresNParts beauftragten Partnern (beispielsweise einer Werbeagentur) gesetzt. Diese externen Partner sind, soweit sie Cookies nutzen, für die von ihnen genutzten Cookies verantwortlich. Wir empfehlen, dass Sie die Datenschutzrichtlinien dieser Partner lesen, die über unsere Cookies Management-Lösung verfügbar sind. Ferner können Sie die Cookies Management-Tools dieser Partner für die Festlegung Ihrer Präferenzen nutzen.</li>
        </ul>
        <p>
          Abgesehen von diesen Merkmalen im Hinblick auf ihre Funktionsweise:<br>
          Können Cookies mehrere Zwecke erfüllen. Beachten Sie bitte, dass die Titel von Kategorien zwischen den verschiedenen Akteuren variieren können. Zum besseren Verständnis werden sie von TyresNParts unter Bezug auf die nachstehenden Kategorien definiert.<br>
          Unbedingt erforderlichen Cookies<br>
          Unbedingt erforderliche Cookies sind Cookies, die unverzichtbar sind aufgrund der Tatsache, dass die Funktionalität der Website von ihrer Existenz abhängig ist. Ihre Deaktivierung wird erhebliche Schwierigkeiten in der Nutzung der Website verursachen, möglich ist sogar, dass die von ihr vorgeschlagenen Services nicht mehr genutzt werden können. Diese Cookies erfüllen zahlreiche Zwecke. Beispielsweise ermöglichen sie die Identifizierung der Terminals für den Versand von Mitteilungen, die Zuweisung von Nummern zu den Daten-„Paketen“, damit diese in der vorgegebenen Reihenfolge versandt werden, sowie die Erkennung von Übertragungsfehlern oder Datenverlusten, sie speichern den Inhalt eines Baskets während der Session, passen die Benutzeroberfläche (Sprache, Präsentation eines Service) oder selbst die Authentifizierung mit einem Service an.<br>
          Diese Cookies speichern ebenfalls die von Benutzern getroffene Wahl im Hinblick auf die bevorzugten Cookies, sodass sie während eines definierten Zeitraums nicht erneut angefordert werden müssen.<br>
          Diese Cookies basieren auf den legitimen Interessen von TyresNParts. Sie sind für Ihre Navigation unbedingt notwendig, folglich bedarf es nicht Ihrer vorherigen Zustimmung. Diese Cookies werden NICHT von unser Cookies Management-Lösung verwaltet.<br>
        </p>
        <br>
        <pre>
          UNBEDINGT ERFORDERLICHE COOKIES<br>
          Name			Put in place by	Objective			Duration
          SessionID		TyresNParts  						Identifizierung des eingeloggten Benutzers über Session ID	Ende der Browser-Sitzung
          SessionID PHP	TyresNParts 						Identifizierung des eingeloggten Benutzers über Session ID	Ende der Browser-Sitzung
        </pre><br>
        <p>
          Funktionale Cookies<br>
          Funktionale Cookies dienen der Verbesserung Ihrer Browser-Erfahrung. Sie erinnern sich an die von Ihnen getroffenen Wahlen (Ihr Benutzername, sie speichern beispielsweise die letzten Routenoptionen) und bieten personalisierte Services. Dank dieser Cookies müssen Sie nicht jedes Mal, wenn Sie bestimmte Informationen eingeben,die Verbindung zu unserer Website oder App herstellen. Die Cookies können Änderungen speichern, die Sie an der Textgröße, der Schriftart oder sonstigen Teilen der Websites, die angepasst werden können, vorgenommen haben.<br>
          Funktionale Cookies werden nicht für den Versand von Werbung auf unserer Website oder auf sonstigen Websites an Sie genutzt.<br>
          Es steht Ihnen frei, diese zu akzeptieren oder abzulehnen, wenn diese jedoch blockiert werden, besteht die Möglichkeit, dass die von Ihnen zuvor getroffene Service-Wahl nicht gespeichert wird.<br>
          Analyse-Cookies und Audio Measurement-Cookies<br>
          Die Audience Measurement-Cookies unterstützen uns in der Erkennung von Besuchern der Website quer durch ihre verschiedenen Besuche. Diese Cookies speichern nur die ID eines Internet-Benutzers (speziell für jedes Cookie) und werden in keinem Fall für die Erfassung namentlicher Informationen, die Eigentum der Besucher sind, genutzt. Die erfassten Daten ermöglichen die Bereitstellung anonymer statistischer Daten in Verbindung mit Besuchen (die Anzahl der Besucher und der besuchten Seiten, die Häufigkeit ihrer Wiederkehr, die Dauer jedes Besuches und die erfolgten Fehlermeldungen ...) Sie unterstützen im Erreichen von Verbesserungen in der Leistung der Internet-Websites von TyresNParts.<br>
          Die Analyse-Cookies können von Partnern installiert und verwaltet werden.<br>
          Es steht Ihnen frei, diese zu akzeptieren oder abzulehnen, wenn diese jedoch blockiert werden, stehen TyresNParts weniger Informationen für die Verbesserung der Leistung seiner Website zur Verfügung.<br>
          Die Nutzung dieser Cookies bedarf Ihrer vorherigen Zustimmung.<br>
          Für zielgerichtete Werbung verwendete Cookies<br>
          Tracking- und Targeting-Cookies befähigen dritte Parteien zum Angebot von Dienstleistungen, vorwiegend Werbung, und zur Steigerung der Wirksamkeit dieser Services. Diese Cookies können die von Ihnen besuchten Webseiten und die Sites speichern und personenbezogene Daten erfassen, vorwiegend die IP-Adresse des Benutzergeräts. Die erfassten Informationen können an dritte Parteien weitergegeben werden.<br>
          Beispiele umfassen Cookies, die Werbeagenturen zur Schaltung von Anzeigen befähigen, die auf Ihren Interessen basieren und anhand der Art Ihres Browsens durch TyresNParts Websites bestimmt werden, oder die ihre Wiederholung begrenzen. Die Cookies bedürfen Ihrer Zustimmung. In diesem Fall erscheint auf der ersten Seite der Website ein deutlich sichtbares Banner, das Ihre Zustimmung zur Installation dieser Cookies anfordert.<br>
          TyresNParts und Drittanbieter, einschließlich Google, verwenden Erstanbieter- und Drittanbieter-Cookies für die Erfassung von Informationen und die Optimierung und das Schalten von Anzeigen, die auf Besuchen der Website basieren. TyresNParts und die genannten Anbieter verwenden diese beiden Cookie-Typen ebenfalls für die Bestimmung des Verhältnisses der Anzahl der Website-Besuche zu den Ad Impressions, sonstigen Inanspruchnahmen von Werbeleistungen und den Wechselwirkungen mit diesen Ad Impressionen und diesen Werbeleistungen. Schließlich verwenden TyresNParts und die genannten Anbieter diese beiden Cookie-Typen für die Speicherung ihrer Interessen und demografischen Daten, um Ihnen zielgerichtete Werbung anzubieten.<br>
          Die Nutzung dieser Cookies bedarf Ihrer vorherigen Zustimmung.<br>
          Von sozialen Netzwerken mittels Social Media Sharing Buttons generierte Cookies<br>
          TyresNParts verwendet so genannte Social Plugins (nachstehend Buttons genannt), die Websites mit sozialen Netzwerken, wie Facebook, LinkedIn und YouTube verbinden.<br>
          Wenn Sie unsere Website besuchen, werden diese Buttons standardmäßig deaktiviert, was bedeutet, dass sie ohne Zutun Ihrerseits keine Informationen an die betreffenden sozialen Netzwerke senden.<br>
          Bevor Sie diese Buttons anwenden können, müssen diese angeklickt und aktiviert werden. Diese Buttons bleiben aktiviert, bis sie von Ihnen deaktiviert werden oder bis Sie Ihre Cookies löschen. Sobald sie deaktiviert wurden, wird das System direkt an den Server des gewählten sozialen Netzwerks angeschlossen. Der Inhalt des Buttons wird dann direkt von den sozialen Netzwerken an Ihren Web-Browser übertragen, der ihn in die von Ihnen besuchte Website integriert.<br>
          Sobald Sie einen Button aktiviert haben, kann das betreffende soziale Netzwerk Daten unabhängig davon erfassen, ob Sie den Button benutzen oder nicht benutzen. Wenn Sie in ein soziales Netzwerk eingeloggt sind, kann dies einen Link zwischen Ihrem Besuch dieser Website und Ihrem Benutzerkonto herstellen. Zwischen Besuchen anderer TyresNParts -Websites werden nur dann Links hergestellt, wenn Sie den entsprechenden Button auf diesen Websites aktiviert haben.<br>
          Wenn Sie ein Mitglied eines sozialen Netzwerks sind und keinen Link zwischen den aus Ihrem Besuch unserer Website erfassten Daten und den Informationen herstellen wollen, die zum Zeitpunkt Ihres Beitritts zum Netzwerk erteilt wurden, müssen Sie sich vor der Aktivierung der Buttons aus dem sozialen Netzwerk ausloggen.<br>
          Beachten Sie bitte, dass wir keinen Einfluss auf den Umfang der Daten haben, die von sozialen Netzwerken über Ihre Buttons erfasst werden. Beachten Sie bitte die von den betreffenden sozialen Netzwerken veröffentlichten Datenschutzerklärungen, wenn Sie weitere Informationen über den Umfang und den Zweck der Datenerfassung und die Art der Verarbeitung und Nutzung dieser Daten sowie über die Rechte und Einstellungsoptionen zum Schutz Ihrer Privatsphäre wünschen.<br>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

};
</script>

<style scoped>
ul {
    list-style: disc;
}
</style>
